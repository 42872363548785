<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t("__add_payment_form_title") }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <v-select
                outlined
                dense
                v-model="form.method"
                :items="paymentItems"
                :label="$t('__add_payment_form_optinos')"
                item-text="ch"
                item-value="en"
              ></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                v-model.number="form.amount"
                type="number"
                :rules="[requiredRule, numberRule]"
                :label="$t('__add_payment_form_amount') + ' *'"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <template v-if="form.method === 'transfer'">
            <v-row align="center">
              <v-col cols="12">
                <v-text-field
                  v-model="form.accountLast5Digits"
                  outlined
                  dense
                  type="number"
                  :rules="[requiredRule, accountLast5DigitsRule]"
                  :label="
                    $t('__add_payment_form_transfer_account_last5_digits') +
                      ' *'
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  :label="$t('__add_payment_form_transfer_bank_code')"
                  v-model="form.bankCode"
                >
                </v-text-field>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  outlined
                  dense
                  type="number"
                  :label="$t('__add_payment_form_transfer_account')"
                  v-model="form.account"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        $t("__confirm")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "AddPaymentForm",
  props: {
    amount: {
      type: Number,
      default: 0
    },
    paymentMethod: {
      type: String,
      default: "cash"
    }
  },
  data() {
    return {
      form: {
        method: "cash",
        amount: "",
        bankCode: "",
        account: "",
        accountLast5Digits: ""
      },
      paymentItems: [
        {
          ch: this.$t("__add_payment_form_cash"),
          en: "cash"
        },
        {
          ch: this.$t("__add_payment_form_cash-on-delivery"),
          en: "cash-on-delivery"
        },
        {
          ch: this.$t("__add_payment_form_transfer"),
          en: "transfer"
        }
      ],
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      numberRule: v => {
        const pattern = /^[0-9]+$/;
        return pattern.test(v) || this.$t("__invalid");
      },
      accountLast5DigitsRule: v => v.length == 5 || this.$t("__invalid")
    };
  },
  methods: {
    reset() {
      this.form = {
        method: "cash",
        amount: "",
        bankCode: "",
        account: "",
        accountLast5Digits: ""
      };
    },
    cancel() {
      this.$emit("cancel");
    },
    ok() {
      this.$emit("ok", this.form);
      this.form.method = "cash";
    }
  },
  watch: {
    amount: {
      immediate: true,
      handler(newValue) {
        this.form.amount = newValue.toString();
      }
    },
    paymentMethod: {
      immediate: true,
      handler(newValue) {
        if (newValue === "cash-on-delivery" || newValue === "transfer") {
          this.form.method = newValue;
        } else {
          this.form.method = "cash";
        }
      }
    }
  }
};
</script>
