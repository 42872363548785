<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t("__reply_cancellation_form_title") }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="form.acceptText">
                <v-radio value="accept">
                  <template v-slot:label>
                    <div><strong class="success--text">{{ $t('__reply_cancellation_form_accept') }}</strong> {{ $t('__reply_cancellation_form_cancellation') }}</div>
                  </template>
                </v-radio>
                <v-radio value="reject">
                  <template v-slot:label>
                    <div><strong class="error--text">{{ $t('__reply_cancellation_form_reject') }}</strong> {{ $t('__reply_cancellation_form_cancellation') }}</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-bind:label="$t('__reply_cancellation_form_description')"
                v-model="form.description">
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{ $t("__cancel") }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{ $t("__confirm") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      form: {
        acceptText: "accept",
        description: ""
      },
      isValid: true,
      requiredRule: v => !!v || this.$t('__required')
    }
  },
  methods: {
    ok() {
      const accept = this.form.acceptText === "accept"
      this.$emit("ok", {
        accept,
        description: this.form.description
      })
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>