<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t("__ship_form_title") }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row align="center">
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                :label="numberLabel"
                v-model="form.logisticsNumber"
                required
                :rules="[requiredRule]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" v-if="isLogistics">
            <v-col cols="12">
              <v-combobox
                outlined
                dense
                :items="logisticsName"
                :label="$t('__order_shipping_logistics_company_name')"
                v-model="form.logisticsCompanyName"
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row align="center" v-if="isLogistics">
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                :label="$t('__order_shipping_logistics_tracking_url')"
                v-model="form.logisticsTrackingURL"
                :rules="[urlRule]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{
        $t("__confirm")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { format } from "date-fns";

export default {
  computed: {
    ...mapGetters({ shippingChannelDatas: "store/shippingChannelDatas" }),
    numberLabel() {
      if (this.isLogistics) {
        return this.$t("__order_shipping_logistics_number") + " *";
      } else {
        return this.$t("__order_shipping_pickup_number") + " *";
      }
    }
  },
  props: {
    storeID: {
      type: String
    },
    isLogistics: {
      type: Boolean
    }
  },
  data() {
    return {
      form: {
        logisticsNumber: "",
        logisticsCompanyName: "",
        logisticsTrackingURL: ""
      },
      isValid: true,
      requiredRule: v => !!v || this.$t("__required"),
      urlRule: v => !v || this.isURL(v) || this.$t("__invalid"),
      logisticsName: []
    };
  },
  methods: {
    isURL(str) {
      try {
        let url = new URL(str);
        return url.protocol === "http:" || url.protocol === "https:";
      } catch (_) {
        return false;
      }
    },
    clear() {
      this.form.logisticsTrackingURL = "";
      this.form.logisticsNumber = "";
    },
    ok() {
      this.$emit("ok", {
        logisticsNumber: this.form.logisticsNumber,
        logisticsCompanyName: this.form.logisticsCompanyName,
        logisticsTrackingURL: this.form.logisticsTrackingURL
      });
    },
    cancel() {
      this.$emit("cancel");
    },
    ...mapActions({
      loadShippingChannelDatas: "store/getStoreShippingChannelDatas"
    })
  },
  watch: {
    storeID: {
      immediate: true,
      handler(val) {
        if (val && this.isLogistics) {
          this.loadShippingChannelDatas(val);
        } else {
          this.$store.commit("store/CLEAR_SHIPPING_CHANNEL_DATAS");
        }
      }
    },
    shippingChannelDatas: {
      immediate: true,
      handler(val) {
        this.form.logisticsCompanyName = "";
        if (val && this.isLogistics) {
          let datas = val.map(item => {
            return item.data.filter(item => {
              return item.data.type === "logistics";
            });
          });
          if (datas[0]) {
            for (const data of datas[0]) {
              this.logisticsName.push(data.data.name);
            }
          }
          this.form.logisticsCompanyName = this.logisticsName[0];
        }
      }
    },
    isLogistics: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.form.logisticsNumber = format(new Date(), "yyyyMMddHHmmss");
        }
      }
    }
  }
};
</script>
