<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('__reply_transferinfo_form_title') }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="isValid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <table class="transferinfo-datatable">
                <tr>
                  <th>{{ $t('__reply_transferinfo_form_text_accountlast5digits') }}</th>
                  <td>{{ this.transferInfo? this.transferInfo.data.account_last_5_digits : '' }}</td>
                </tr>
                <tr>
                  <th>{{ $t('__reply_transferinfo_form_text_amount') }}</th>
                  <td>${{ this.transferInfo? this.transferInfo.data.amount : '' }}</td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="form.confirmText">
                <v-radio value="confirm">
                  <template v-slot:label>
                    <div><strong class="success--text">{{ $t('__reply_transferinfo_form_confirm') }}</strong> {{ $t('__reply_transferinfo_form_transferinfo') }}</div>
                  </template>
                </v-radio>
                <v-radio value="reject">
                  <template v-slot:label>
                    <div><strong class="error--text">{{ $t('__reply_transferinfo_form_reject') }}</strong> {{ $t('__reply_transferinfo_form_transferinfo') }}</div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-bind:label="$t('__reply_transferinfo_form_description')"
                v-model="form.description">
              </v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="cancel">{{ $t("__cancel") }}</v-btn>
      <v-btn color="blue darken-1" text @click="ok" :disabled="!isValid">{{ $t("__confirm") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    transferInfo: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        confirmText: "confirm",
        description: ""
      },
      isValid: true,
      requiredRule: v => !!v || this.$t('__required')
    }
  },
  methods: {
    ok() {
      const confirm = this.form.confirmText === "confirm"
      this.$emit("ok", {
        confirm,
        description: this.form.description
      })
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>

<style lang="scss">
.transferinfo-datatable {
  th {
    text-align: left;
    padding-right: 0.5em;
  }
}
</style>
